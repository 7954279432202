import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { VENUES_PATH } from '../constants';
import { DeviceModelRequestDto, VenueModelRequestDto, VenueModelResponseDto } from '../models';
import { ApiService } from './api.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class VenueService {
  readonly #apiService = inject(ApiService<VenueModelRequestDto, VenueModelResponseDto>);
  readonly #localStorageService = inject(LocalStorageService);

  // Make sources private so it's not accessible from the outside
  readonly #venueSource: WritableSignal<VenueModelResponseDto | undefined> = signal(undefined);
  readonly #venueIdSource: WritableSignal<string | undefined> = signal(undefined);
  readonly #tableIdSource: WritableSignal<string | undefined> = signal(undefined);

  // Exposed signals (read-only)
  readonly venue = this.#venueSource.asReadonly();
  readonly venueId = this.#venueIdSource.asReadonly();
  readonly tableId = this.#tableIdSource.asReadonly();

  constructor() {}

  setVenue(venue: VenueModelResponseDto) {
    this.#venueIdSource.set(venue._id);
    const tableId = venue.tables?.[0]?._id;
    if (tableId) {
      this.#tableIdSource.set(tableId);
    }

    this.#localStorageService.saveData('venue', this.venueId());
    this.#localStorageService.saveData('table', this.tableId());

    this.#venueSource.set(venue);
  }

  getVenue(venueUrl: string, tableUrl: string, device: DeviceModelRequestDto): Promise<VenueModelResponseDto> {
    return this.#apiService.post(
      `${VENUES_PATH}/session?venueUrl=${venueUrl}&tableUrl=${tableUrl}`,
      device as unknown as VenueModelRequestDto,
    );
  }
}
