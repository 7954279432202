import { HttpClient } from '@angular/common/http';
import { Injectable, Signal, WritableSignal, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { debounceTime, firstValueFrom, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DEVICES_PATH, httpOptions } from '../constants';
import { DeviceModelRequestDto, JwtModel } from '../models';
import { UserAccountService } from './user-account.service';

@Injectable({ providedIn: 'root' })
export class UserDeviceService {
  readonly #httpClient = inject(HttpClient);
  readonly #userAccountService = inject(UserAccountService);

  // Make sources private so it's not accessible from the outside
  readonly #userDeviceSource: WritableSignal<DeviceModelRequestDto | undefined> = signal(undefined);

  // Exposed signals (read-only)
  readonly userDevice: Signal<DeviceModelRequestDto | undefined> = this.#userDeviceSource.asReadonly();
  readonly userDevice$ = toObservable(this.userDevice);

  constructor() {}

  setUserDevice(userDevice: DeviceModelRequestDto) {
    this.#userDeviceSource.set(userDevice);
  }

  public loginDevice(device: DeviceModelRequestDto) {
    return firstValueFrom(
      this.#httpClient
        .post<JwtModel>(`${environment.API_URL}/api/${DEVICES_PATH}/auth`, device, httpOptions)
        .pipe(shareReplay(1), debounceTime(1000)),
    ).then(jwtTokens => {
      this.setUserDevice(device);
      this.#userAccountService.setUserAccount(jwtTokens);
    });
  }
}
