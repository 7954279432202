import { Injectable, Signal, WritableSignal, inject, signal } from '@angular/core';
import { SESSIONS_PATH, TABLES_PATH, VENUES_PATH } from '../constants';
import { SessionModelRequestDto, SessionModelResponseDto } from '../models';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class SessionsService {
  readonly #apiService = inject(ApiService<SessionModelRequestDto, SessionModelResponseDto>);

  // Make sources private so it's not accessible from the outside
  readonly #sessionSource: WritableSignal<SessionModelResponseDto | undefined> = signal(undefined);

  // Exposed signals (read-only)
  readonly session: Signal<SessionModelResponseDto | undefined> = this.#sessionSource.asReadonly();

  async acceptUser(userId: string, sessionId: string) {
    await this.#apiService.post(`${SESSIONS_PATH}/${sessionId}/users/accept`, { id: userId } as unknown as SessionModelRequestDto);
  }

  async declineUser(userId: string, sessionId: string) {
    await this.#apiService.post(`${SESSIONS_PATH}/${sessionId}/users/decline`, { id: userId } as unknown as SessionModelRequestDto);
  }

  async acceptUserByProvidingVenueAndTable(userId: string, venueId: string, tableId: string) {
    await this.#apiService.post(`${SESSIONS_PATH}/${venueId}/${tableId}/users/accept`, {
      id: userId,
    } as unknown as SessionModelRequestDto);
  }

  async declineUserByProvidingVenueAndTable(userId: string, venueId: string, tableId: string) {
    await this.#apiService.post(`${SESSIONS_PATH}/${venueId}/${tableId}/users/decline`, {
      id: userId,
    } as unknown as SessionModelRequestDto);
  }

  async deleteSessionByVenueAndTable(venueId: string, tableId: string) {
    return this.#apiService.delete(`${VENUES_PATH}/${venueId}/${TABLES_PATH}/${tableId}/${SESSIONS_PATH}`);
  }
}
