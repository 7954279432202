import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, TemplateRef, contentChild } from '@angular/core';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './app-modal.component.html',
  styleUrls: ['./app-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppModalComponent {
  template = contentChild<TemplateRef<unknown>>('template');

  get context() {
    return this;
  }
}
