import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { PRODUCTS_PATH, SIZES_PATH, VENUES_PATH } from '../constants';
import { SizeModelRequestDto, SizeModelResponseDto } from '../models';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class SizesService {
  readonly #apiService = inject(ApiService<SizeModelRequestDto, SizeModelResponseDto>);

  // Make sources private so it's not accessible from the outside
  readonly #sizesSource: WritableSignal<SizeModelResponseDto[] | undefined> = signal(undefined);

  // Exposed signals (read-only)
  readonly sizes = this.#sizesSource.asReadonly();

  getAllSizesOfAVenue(venueId: string): Promise<SizeModelResponseDto[]> {
    return this.#apiService.getAll(`${VENUES_PATH}/${venueId}/${SIZES_PATH}`);
  }

  getAllSizesOfAProduct(productId: string): Promise<SizeModelResponseDto[]> {
    return this.#apiService.getAll(`${PRODUCTS_PATH}/${productId}/${SIZES_PATH}`);
  }

  createSizes(sizes: SizeModelRequestDto[]): Promise<SizeModelResponseDto[]> {
    return this.#apiService.postMany(`${SIZES_PATH}`, sizes);
  }

  setSizes(sizes: SizeModelResponseDto[]) {
    this.#sizesSource.set(sizes);
  }
}
