@for (menuItem of navigationLinks(); track menuItem.route) {
  <div class="menu-bar__item" routerLinkActive="menu-bar__item-active" [routerLink]="menuItem.route">
    <p class="menu-bar__item__title">
      {{ menuItem.shortTranslatePath || 'menu' | translate }}
    </p>
    <span class="menu-bar__item__icon" [title]="menuItem.title | titlecase">
      <waytr-icon [name]="menuItem.icon || 'menu'"></waytr-icon>
    </span>
  </div>
}
