import { Injectable, inject } from '@angular/core';
import { SUBORDERS_PATH, TABLES_PATH, VENUES_PATH } from '../constants';
import { SuborderModelRequestDto, SuborderModelResponseDto } from '../models';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class SubordersService {
  readonly #apiService = inject(ApiService<SuborderModelRequestDto, SuborderModelResponseDto>);

  getSubordersByVenueAndTable(venueId: string, tableId: string): Promise<SuborderModelResponseDto[]> {
    return this.#apiService.getAll(`${VENUES_PATH}/${venueId}/${TABLES_PATH}/${tableId}/${SUBORDERS_PATH}`);
  }

  createSuborder(suborderItem: SuborderModelRequestDto): Promise<SuborderModelResponseDto> {
    return this.#apiService.post(SUBORDERS_PATH, suborderItem);
  }

  deleteTableSuborders(venueId: string, tableId: string) {
    return this.#apiService.delete(`${VENUES_PATH}/${venueId}/${TABLES_PATH}/${tableId}/${SUBORDERS_PATH}`);
  }
}
