/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpErrorResponse } from '@angular/common/http';

export function getErrorMessage(error: HttpErrorResponse | any): string {
  let errorMessage = 'An error occurred.';
  if (error instanceof HttpErrorResponse) {
    // Server-side error
    errorMessage = `Error Code: ${error.status}\nMessage: ${error.error?.error || error.error?.message || error.statusText}`;
  } else if (error && Object.prototype.hasOwnProperty.call(error, 'message')) {
    // Client-side error
    errorMessage = error.message;
  }

  return errorMessage;
}
