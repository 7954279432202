import { Injectable, inject } from '@angular/core';
import { PRODUCTS_PATH } from '../constants';
import { ProductModelRequestDto, ProductModelResponseDto } from '../models';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class ProductService {
  readonly #apiService = inject(ApiService<ProductModelRequestDto, ProductModelResponseDto>);

  getProductById(id: string): Promise<ProductModelResponseDto> {
    return this.#apiService.getOneById(PRODUCTS_PATH, id);
  }
}
