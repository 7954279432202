import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { ISessionParams } from '../interfaces';

@Injectable({
  providedIn: 'root',
})
export class RouterStateService {
  // Make sources private so it's not accessible from the outside
  readonly #routingParamsSource: WritableSignal<ISessionParams | undefined> = signal(undefined);
  readonly #appMenuBarShownSource: WritableSignal<boolean> = signal(false);

  // Exposed signals (read-only)
  readonly routingParams: Signal<ISessionParams | undefined> = this.#routingParamsSource.asReadonly();
  readonly routingParams$ = toObservable(this.routingParams);
  readonly appMenuBarShown: Signal<boolean> = this.#appMenuBarShownSource.asReadonly();

  setRoutingParams(route: ISessionParams | undefined) {
    if (route) {
      this.#routingParamsSource.set(route);
    }
  }

  hideAppMenuBar() {
    this.#appMenuBarShownSource.set(false);
  }

  showAppMenuBar() {
    this.#appMenuBarShownSource.set(true);
  }
}
