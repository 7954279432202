import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Signal, computed, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BOTTOM_MENU_LINKS } from 'src/app/constants';
import { updateLinks } from 'src/app/helpers';
import { ISessionParams } from 'src/app/interfaces';
import { AppLinksTypeModel } from 'src/app/models/types';
import { RouterStateService } from 'src/app/services';
import { WaytrIcon } from '../waytr-icon';

@Component({
  selector: 'app-menu-bar',
  standalone: true,
  templateUrl: './app-menu-bar.component.html',
  styleUrls: ['./app-menu-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterModule, TranslateModule, WaytrIcon],
})
export class AppMenuBarComponent {
  readonly #routerStateService = inject(RouterStateService);

  readonly #routingParams: Signal<ISessionParams | undefined> = this.#routerStateService.routingParams;
  protected readonly navigationLinks: Signal<AppLinksTypeModel[]> = computed(() => this.calculateNavigationLinks(this.#routingParams()));

  private calculateNavigationLinks(routingParams: ISessionParams | undefined) {
    return routingParams ? updateLinks(BOTTOM_MENU_LINKS, this.#routingParams()) : BOTTOM_MENU_LINKS;
  }
}
