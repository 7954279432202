import { Injectable, Signal, WritableSignal, inject, signal } from '@angular/core';
import { VENUES_PATH, WAITER_COMMANDS_OPTIONS_PATH } from '../constants';
import { WaiterCommandOptionModelRequestDto, WaiterCommandOptionModelResponseDto } from '../models';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class WaiterCommandOptionsService {
  readonly #apiService = inject(ApiService<WaiterCommandOptionModelRequestDto, WaiterCommandOptionModelResponseDto>);

  // Make sources private so it's not accessible from the outside
  readonly #waiterCommandOptionsSource: WritableSignal<WaiterCommandOptionModelResponseDto[]> = signal([]);

  // Exposed signals (read-only)
  readonly waiterCommandOptions: Signal<WaiterCommandOptionModelResponseDto[] | undefined> = this.#waiterCommandOptionsSource.asReadonly();

  getWaiterCommandOptions(venueId: string): Promise<WaiterCommandOptionModelResponseDto[]> {
    return this.#apiService.getAll(`${VENUES_PATH}/${venueId}/${WAITER_COMMANDS_OPTIONS_PATH}`);
  }

  setWaiterCommandsOptions(waiterCommandOptions: WaiterCommandOptionModelResponseDto[]) {
    this.#waiterCommandOptionsSource.set(waiterCommandOptions);
  }
}
