import { Injectable, inject } from '@angular/core';
import { NOTIFICATIONS_PATH, VENUES_PATH } from '../constants';
import { AccountTypeEnum, NotificationModelRequestDto, NotificationModelResponseDto } from '../models';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  readonly #apiService = inject(ApiService<NotificationModelRequestDto, NotificationModelResponseDto>);

  deleteNotifications(ids: string[]): Promise<void> {
    return this.#apiService.deleteMany(NOTIFICATIONS_PATH, ids);
  }

  getWaiterNotificationsOfAVenue(venueId: string): Promise<NotificationModelResponseDto[]> {
    return this.#apiService.getAll(`${VENUES_PATH}/${venueId}/${NOTIFICATIONS_PATH}?to=${AccountTypeEnum.WAITER}`);
  }
}
