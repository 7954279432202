import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { debounceTime, firstValueFrom, Observable, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExcludedParams, httpOptions } from '../constants';
import { ILooseObject } from '../interfaces';
import { WaytrBaseIdModel, WaytrBaseModel, WaytrBaseTimestampModel } from '../models';

@Injectable({ providedIn: 'root' })
export class ApiService<
  RequestDTO extends Omit<unknown, ExcludedParams>,
  ResponseDTO extends WaytrBaseModel | WaytrBaseIdModel | WaytrBaseTimestampModel,
> {
  readonly #httpClient = inject(HttpClient);
  readonly #apiBaseUrl = `${environment.API_URL}/api`;

  private sendRequest<T>(method: string, apiPath: string, options: ILooseObject, payload?: unknown): Promise<T> {
    return firstValueFrom(
      this.#httpClient
        .request<T>(method, `${this.#apiBaseUrl}/${apiPath}`, { ...httpOptions, ...options, body: payload })
        .pipe(shareReplay(1), debounceTime(500)) as Observable<T>,
    );
  }

  public getAll(apiPath: string, options = httpOptions): Promise<ResponseDTO[]> {
    return this.sendRequest<ResponseDTO[]>('GET', apiPath, options);
  }

  public getOneById(apiPath: string, id: string, options = httpOptions): Promise<ResponseDTO> {
    return this.getOne(`${apiPath}/${id}`, options);
  }

  public getOne(apiPath: string, options = httpOptions): Promise<ResponseDTO> {
    return this.sendRequest<ResponseDTO>('GET', apiPath, options);
  }

  public getMany(apiPath: string, options = httpOptions): Promise<ResponseDTO[]> {
    return this.sendRequest<ResponseDTO[]>('GET', apiPath, options);
  }

  public post(apiPath: string, payload?: Partial<RequestDTO>, options = httpOptions): Promise<ResponseDTO> {
    return this.sendRequest<ResponseDTO>('POST', apiPath, options, payload);
  }

  public postMany(apiPath: string, payload?: RequestDTO[], options = httpOptions): Promise<ResponseDTO[]> {
    return this.sendRequest<ResponseDTO[]>('POST', apiPath, options, payload);
  }

  public patch(apiPath: string, payload?: Partial<RequestDTO>, options = httpOptions): Promise<ResponseDTO> {
    return this.sendRequest<ResponseDTO>('PATCH', apiPath, options, payload);
  }

  public put(apiPath: string, payload?: RequestDTO, options = httpOptions): Promise<ResponseDTO> {
    return this.sendRequest<ResponseDTO>('PUT', apiPath, options, payload);
  }

  public delete(apiPath: string, id?: string, options = httpOptions): Promise<ResponseDTO> {
    if (id) {
      return this.sendRequest<ResponseDTO>('DELETE', `${apiPath}/${id}`, options);
    } else {
      return this.sendRequest<ResponseDTO>('DELETE', apiPath, options);
    }
  }

  public deleteMany(apiPath: string, ids: Array<string>, options = httpOptions): Promise<void> {
    return this.sendRequest<void>('DELETE', apiPath, options, { ids });
  }
}
