import { Injectable, inject } from '@angular/core';
import { CART_ITEMS_PATH } from '../constants';
import { CartItemModelRequestDto, CartItemModelResponseDto, SuborderModelRequestDto, SuborderStatusEnum } from '../models';
import { ApiService } from './api.service';
import { OrdersService } from './orders.service';

@Injectable({ providedIn: 'root' })
export class CartService {
  readonly #apiService = inject(ApiService<CartItemModelRequestDto, CartItemModelResponseDto>);
  readonly #ordersService = inject(OrdersService);

  addCartItems(cart: CartItemModelRequestDto[]): Promise<CartItemModelResponseDto[]> {
    return this.#apiService.postMany(CART_ITEMS_PATH, cart);
  }

  changeQuantity(cartItemId: string, quantity: number): Promise<CartItemModelResponseDto> {
    const cartItem: Partial<CartItemModelRequestDto> = {
      quantity: quantity,
    };

    if (cartItem.quantity === 0) {
      return this.#apiService.delete(CART_ITEMS_PATH, cartItemId);
    }

    return this.#apiService.patch(`${CART_ITEMS_PATH}/${cartItemId}`, cartItem);
  }

  changeInstructions(cartItemId: string, instructions: string): Promise<CartItemModelResponseDto> {
    const cartItem: Partial<CartItemModelRequestDto> = {
      instructions,
    };

    return this.#apiService.patch(`${CART_ITEMS_PATH}/${cartItemId}`, cartItem);
  }

  removeCartItem(cartItemId: string): Promise<CartItemModelResponseDto> {
    return this.#apiService.delete(CART_ITEMS_PATH, cartItemId);
  }

  mapToSuborderRequestDto(cartItems: CartItemModelResponseDto[]): SuborderModelRequestDto {
    return {
      venue: cartItems[0].venue as unknown as string,
      table: cartItems[0].table as unknown as string,
      cartItems: cartItems.map(obj => obj._id),
      status: SuborderStatusEnum.NEW,
      order: this.#ordersService.sessionOrder()?._id as string,
    };
  }
}
