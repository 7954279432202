import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { AllergenModelResponseDto, IngredientModelResponseDto, ProductModelResponseDto, SizeModelResponseDto } from '../models';
import { AllergensService } from './allergens.service';
import { IngredientsService } from './ingredients.service';
import { SizesService } from './sizes.service';

@Injectable({ providedIn: 'root' })
export class VenueMenuItemModalService {
  readonly #allergensService = inject(AllergensService);
  readonly #ingredientsService = inject(IngredientsService);
  readonly #sizesService = inject(SizesService);

  // Make sources private so it's not accessible from the outside
  readonly #venueMenuItemModalSource: WritableSignal<ProductModelResponseDto | undefined> = signal(undefined);
  readonly #venueMenuItemModalAllergensSource: WritableSignal<AllergenModelResponseDto[] | undefined> = signal(undefined);
  readonly #venueMenuItemModalIngredientsSource: WritableSignal<IngredientModelResponseDto[] | undefined> = signal(undefined);
  readonly #venueMenuItemModalSizesSource: WritableSignal<SizeModelResponseDto[] | undefined> = signal(undefined);

  // Exposed signals (read-only)
  readonly venueMenuItemModal = this.#venueMenuItemModalSource.asReadonly();
  readonly venueMenuItemModalAllergens = this.#venueMenuItemModalAllergensSource.asReadonly();
  readonly venueMenuItemModalIngredients = this.#venueMenuItemModalIngredientsSource.asReadonly();
  readonly venueMenuItemModalSizes = this.#venueMenuItemModalSizesSource.asReadonly();

  setMenuItemModal(product: ProductModelResponseDto | undefined) {
    this.#venueMenuItemModalSource.set(product);

    if (product) {
      this.#allergensService.getAllAllergensOfAProduct(product._id).then(allergens => {
        this.#venueMenuItemModalAllergensSource.set(allergens);
      });

      this.#ingredientsService.getAllIngredientsOfAProduct(product._id).then(ingredients => {
        this.#venueMenuItemModalIngredientsSource.set(ingredients);
      });

      this.#sizesService.getAllSizesOfAProduct(product._id).then(sizes => {
        this.#venueMenuItemModalSizesSource.set(sizes);
      });
    }
  }
}
