import { Pipe, PipeTransform } from '@angular/core';
import { WaytrBaseTimestampModel } from '../models';
@Pipe({
  name: 'sortByUpdatedTime',
  standalone: true,
  pure: false,
})
export class SortByUpdatedTimePipe<T extends WaytrBaseTimestampModel> implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(values: T[]): T[] {
    if (!values || !values.length) {
      return [];
    }

    return values.sort((a, b) => {
      const dateA = new Date(a.updatedAt);
      const dateB = new Date(b.updatedAt);
      // Sort in descending order for most recent date first
      return dateB.getTime() - dateA.getTime();
    });
  }
}
