import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  #isLocalStorageAvailable: boolean = false;

  constructor(@Inject(PLATFORM_ID) private readonly platformId: string) {
    if (isPlatformBrowser(platformId)) {
      this.#isLocalStorageAvailable = true;
    }
  }

  private encrypt(text: string): string {
    return CryptoJS.AES.encrypt(text, environment.LOCAL_STORAGE_KEY).toString();
  }

  private decrypt(text: string) {
    const bytes = CryptoJS.AES.decrypt(text, environment.LOCAL_STORAGE_KEY);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  public saveData(key: string, value: unknown) {
    if (this.#isLocalStorageAvailable) {
      localStorage.setItem(key, this.encrypt(JSON.stringify(value)));
    }
  }

  public savePlainData(key: string, value: unknown) {
    if (this.#isLocalStorageAvailable) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  public getData(key: string) {
    if (this.#isLocalStorageAvailable) {
      const data = localStorage.getItem(key);
      if (data) {
        try {
          return JSON.parse(this.decrypt(data));
        } catch (err) {
          console.debug('Error parsing data from local storage', err);
          return null;
        }
      }
    }

    return null;
  }

  getPlainData(key: string) {
    if (this.#isLocalStorageAvailable) {
      try {
        const data = localStorage.getItem(key);

        if (data) {
          return JSON.parse(data);
        }
      } catch (err) {
        return null;
      }

      return null;
    }
  }

  public removeData(key: string) {
    if (this.#isLocalStorageAvailable) {
      localStorage.removeItem(key);
    }
  }

  public clearData() {
    if (this.#isLocalStorageAvailable) {
      localStorage.clear();
    }
  }
}
