import { Injectable } from '@angular/core';
import { IWaytrIcon } from '../waytr-icons';

@Injectable({
  providedIn: 'root',
})
export class WaytrIconsService {
  private iconsMap = new Map<string, string>();

  public addIcons(icons: IWaytrIcon[]): void {
    icons.forEach((icon: IWaytrIcon) => this.iconsMap.set(icon.name, icon.data));
  }

  public getIcon(iconName: string): string | undefined {
    if (!this.iconsMap.has(iconName)) {
      console.warn(`We could not find the Waytr icon with the name ${iconName}, did you add it to the icons list?`);
    }

    return this.iconsMap.get(iconName);
  }

  public getAllIcons(): Map<string, string> {
    return this.iconsMap;
  }
}
