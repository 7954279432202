import { Injectable, computed, inject } from '@angular/core';
import { SessionStateService } from './session-state.service';

@Injectable({ providedIn: 'root' })
export class OrdersService {
  readonly #sessionStateService = inject(SessionStateService);

  // Exposed signals (read-only)
  readonly sessionOrder = computed(() => this.#sessionStateService.getSession()?.order);
}
