import { effect, Inject, inject, Injectable, Signal } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, shareReplay, Subject, takeUntil } from 'rxjs';
import { buildRoute, isSessionUrlAPredefinedPage } from '../helpers';
import { ISessionParams, IVenueAndTableParams } from '../interfaces';
import { IUserVenueAndTableParams } from '../interfaces/user-venue-and-table-params.interface';
import { CartItemModelResponseDto, SessionModelResponseDto, WaiterCommandModelResponseDto } from '../models';
import { RouterStateService } from './router-state.service';
import { TitleService } from './title.service';
import { UserAccountService } from './user-account.service';
import { UserDeviceService } from './user-device.service';
import { VenueService } from './venue.service';
import { WaiterCommandOptionsService } from './waiter-commands-options.service';
import {
  CART_ITEMS_WEBSOCKET_SERVICE,
  SESSIONS_WEBSOCKET_SERVICE,
  SUBORDERS_WEBSOCKET_SERVICE,
  WAITER_COMMANDS_WEBSOCKET_SERVICE,
  WebsocketService,
} from './websocket.service';

@Injectable({ providedIn: 'root' })
export class SessionStateService {
  readonly #routerStateService = inject(RouterStateService);
  readonly #userDeviceService = inject(UserDeviceService);
  readonly #waiterCommandOptionsService = inject(WaiterCommandOptionsService);
  readonly #venueService = inject(VenueService);
  readonly #router = inject(Router);
  readonly #titleService = inject(TitleService);
  readonly #userAccountService = inject(UserAccountService);

  // Make sources private so it's not accessible from the outside
  readonly #sessionAlreadyCreated: Subject<boolean> = new Subject();

  // Exposed observable (read-only)
  readonly sessionAlreadyCreated$ = this.#sessionAlreadyCreated.asObservable();

  readonly sessions: Signal<SessionModelResponseDto[]> = this.sessionsWebsocketService.messages;

  constructor(
    @Inject(SESSIONS_WEBSOCKET_SERVICE)
    private readonly sessionsWebsocketService: WebsocketService<SessionModelResponseDto>,
    @Inject(CART_ITEMS_WEBSOCKET_SERVICE)
    private readonly cartItemsWebsocketService: WebsocketService<CartItemModelResponseDto>,
    @Inject(SUBORDERS_WEBSOCKET_SERVICE)
    private readonly subordersWebsocketService: WebsocketService<CartItemModelResponseDto>,
    @Inject(WAITER_COMMANDS_WEBSOCKET_SERVICE)
    private readonly waiterCommandsWebsocketService: WebsocketService<WaiterCommandModelResponseDto>,
  ) {
    effect(() => {
      const session = this.sessions()[0];
      const waytrRoutingParams = this.#routerStateService.routingParams();

      if (session && waytrRoutingParams) {
        this.createAppLinks(session, waytrRoutingParams);
      }
    });
  }

  public createAppState() {
    combineLatest([this.#userDeviceService.userDevice$, this.#routerStateService.routingParams$])
      .pipe(shareReplay(1), takeUntil(this.sessionAlreadyCreated$))
      .subscribe(([device, waytrRoutingParams]) => {
        if (waytrRoutingParams && device) {
          // We don't need the subscription anymore as the state is already created
          this.#sessionAlreadyCreated.next(true);
          this.#sessionAlreadyCreated.complete();

          this.#venueService.getVenue(waytrRoutingParams.venue, waytrRoutingParams.table, device).then(venue => {
            this.#userDeviceService.loginDevice(device);
            this.#venueService.setVenue(venue);

            const venueAndTablesParams = {
              venueId: this.#venueService.venueId(),
              tableId: this.#venueService.tableId(),
            } as IVenueAndTableParams;

            const userVenueAndTablesParams = {
              userId: this.#userAccountService.userJWTDetails()?.sub,
              venueId: this.#venueService.venueId(),
              tableId: this.#venueService.tableId(),
            } as IUserVenueAndTableParams;

            this.cartItemsWebsocketService.sendInitialParams(userVenueAndTablesParams);
            this.subordersWebsocketService.sendInitialParams(venueAndTablesParams);
            this.sessionsWebsocketService.sendInitialParams(venueAndTablesParams);
            this.waiterCommandsWebsocketService.sendInitialParams(venueAndTablesParams);

            this.#titleService.loadInitialTitle(venue.name);
            this.#titleService.changeToNewPage('Menu');
            this.#waiterCommandOptionsService
              .getWaiterCommandOptions(venue._id)
              .then(waiterCommandOptions => this.#waiterCommandOptionsService.setWaiterCommandsOptions(waiterCommandOptions));
          });
        }
      });
  }

  getSession(): SessionModelResponseDto | undefined {
    return this.sessions()[0];
  }

  private createAppLinks(session: SessionModelResponseDto, params: ISessionParams) {
    let predefinedPage = '';
    if (isSessionUrlAPredefinedPage(params.sessionUrl)) {
      predefinedPage = params.sessionUrl;
    }

    params.sessionUrl = session.sessionUrl;

    if (predefinedPage) {
      this.#router.navigateByUrl(buildRoute(params, predefinedPage));
    }
  }
}
