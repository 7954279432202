import { Injectable, Signal, WritableSignal, computed, effect, inject, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Environments } from 'src/environments/environments.enum';
import { WAYTR } from '../constants';

@Injectable({ providedIn: 'root' })
export class TitleService {
  readonly #title = inject(Title);

  // Make sources private so it's not accessible from the outside
  readonly #venueTitleSource: WritableSignal<string> = signal('');
  readonly #pageTitleSource: WritableSignal<string> = signal('');

  // Exposed signals (read-only)
  readonly venueTitle: Signal<string> = this.#venueTitleSource.asReadonly();
  readonly pageTitle: Signal<string> = this.#pageTitleSource.asReadonly();
  readonly appTitle: Signal<string> = computed(() => {
    if (this.venueTitle() && this.pageTitle()) {
      return `${this.pageTitle()} | ${this.venueTitle()}`;
    } else if (this.venueTitle() && !this.pageTitle()) {
      return this.venueTitle();
    }

    return WAYTR;
  });

  constructor() {
    effect(() => {
      if (this.appTitle()) {
        this.#title.setTitle(this.appTitle());
      }
    });
  }

  changeToNewPage(page: string) {
    // TODO: Add translation support
    if (this.venueTitle()) {
      this.#pageTitleSource.set(page);
    }
  }

  loadInitialTitle(venueName: string) {
    switch (environment.environment) {
      case Environments.LOCAL:
        this.#venueTitleSource.set(`${Environments.LOCAL.toUpperCase()} - ${venueName}`);
        break;
      case Environments.DEV:
        this.#venueTitleSource.set(`${Environments.DEV.toUpperCase()} - ${venueName}`);
        break;
      case Environments.PROD:
        this.#venueTitleSource.set(venueName);
        break;
      default:
        break;
    }
  }
}
