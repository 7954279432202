import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { INGREDIENTS_PATH, PRODUCTS_PATH, VENUES_PATH } from '../constants';
import { IngredientModelRequestDto, IngredientModelResponseDto } from '../models';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class IngredientsService {
  readonly #apiService = inject(ApiService<IngredientModelRequestDto, IngredientModelResponseDto>);

  // Make sources private so it's not accessible from the outside
  readonly #ingredientsSource: WritableSignal<IngredientModelResponseDto[] | undefined> = signal(undefined);

  // Exposed signals (read-only)
  readonly #ingredients = this.#ingredientsSource.asReadonly();

  getAllIngredientsOfAVenue(venueId: string): Promise<IngredientModelResponseDto[]> {
    return this.#apiService.getAll(`${VENUES_PATH}/${venueId}/${INGREDIENTS_PATH}`);
  }

  getAllIngredientsOfAProduct(productId: string): Promise<IngredientModelResponseDto[]> {
    return this.#apiService.getAll(`${PRODUCTS_PATH}/${productId}/${INGREDIENTS_PATH}`);
  }

  createIngredients(ingredients: IngredientModelRequestDto[]): Promise<IngredientModelResponseDto[]> {
    return this.#apiService.postMany(`${INGREDIENTS_PATH}`, ingredients);
  }

  setIngredients(ingredients: IngredientModelResponseDto[]) {
    this.#ingredientsSource.set(ingredients);
  }

  deleteIngredient(ingredientId: string): Promise<IngredientModelResponseDto> {
    return this.#apiService.delete(`${INGREDIENTS_PATH}`, ingredientId);
  }

  updateIngredient(ingredientId: string, ingredient: Partial<IngredientModelRequestDto>): Promise<IngredientModelResponseDto> {
    return this.#apiService.patch(`${INGREDIENTS_PATH}/${ingredientId}`, ingredient);
  }
}
