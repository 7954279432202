import { Injectable, inject } from '@angular/core';
import { WAITER_COMMANDS_PATH } from '../constants';
import { WaiterCommandModelRequestDto, WaiterCommandModelResponseDto } from '../models';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class WaiterCommandsService {
  readonly #apiService = inject(ApiService<WaiterCommandModelRequestDto, WaiterCommandModelResponseDto>);

  async sendWaiterCommand(request: WaiterCommandModelRequestDto) {
    await this.#apiService.post(WAITER_COMMANDS_PATH, request);
  }
}
