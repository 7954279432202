import { FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { debounce as debounceFn } from 'lodash-es';
import { PagesEnum } from '../models/enums/pages.enum';
import { AppLinksTypeModel } from '../models/types/app-links-type.model';

export function updateLinks(links: AppLinksTypeModel[], routeParams: Params | undefined): AppLinksTypeModel[] {
  const newLinks = links;
  newLinks.forEach(link => {
    link.route = buildRoute(routeParams, link.defaultRoute);
  });

  return newLinks;
}

export const nonNullObject = <T extends object>(objectToTest: object): objectToTest is T =>
  Object.values(objectToTest).every(value => value !== null);

export function buildRoute(routeParams: Params | undefined, route: string): string {
  if (routeParams?.sessionUrl) {
    return `${routeParams?.venue}/${routeParams?.table}/${routeParams?.sessionUrl}/${route}`;
  } else {
    return `${routeParams?.venue}/${routeParams?.table}/${route}`;
  }
}

export function stopPropagation(event: MouseEvent | TouchEvent | KeyboardEvent) {
  event.stopImmediatePropagation();
}

/**
 * Debounce a method
 */
export function debounce(milliseconds = 0, options = {}) {
  return function (target: unknown, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    descriptor.value = debounceFn(originalMethod, milliseconds, options);
    return descriptor;
  };
}

export function isSessionUrlAPredefinedPage(sessionUrl: string): boolean {
  return Object.values(PagesEnum)?.includes(sessionUrl as PagesEnum);
}

export function calculateDiscount(initialPrice: number, discountedPrice: number) {
  return Math.round((1 - discountedPrice / initialPrice) * 100);
}

export function validateRouteParams(paramsString: string): boolean {
  const params = paramsString?.split('/') || '';
  if (params.length >= 5) {
    return Object.values(PagesEnum)?.includes(params[4] as PagesEnum);
  }

  return false;
}

export function minutesDiff(date: string): number {
  const timestamp = new Date(date);
  const currentTime = new Date();
  const diffInMs = currentTime.getTime() - timestamp.getTime();
  const diffInMins = Math.round(diffInMs / (1000 * 60));
  return diffInMins;
}

export function invalidateForm(form: FormGroup) {
  Object.values(form.controls)?.forEach(control => {
    if (control.invalid) {
      control.markAsDirty();
      control.updateValueAndValidity({ onlySelf: true });
    }
  });
}

export function getToday<T>(type: T, day: number): T[keyof T] {
  const casted = day as keyof T;
  return type[casted];
}

export function toPresentationCase(sentence: string | undefined | null): string {
  if (!sentence) return '';
  return sentence[0].toUpperCase() + sentence.substring(1);
}

export function getTime(date: Date) {
  if (!date) return '';

  // Get the hours and minutes in 12-hour format (AM/PM)
  const hours = date.getHours() % 12;
  const minutes = date.getMinutes();

  // Determine whether it's AM or PM
  const period = date.getHours() >= 12 ? 'PM' : 'AM';

  // Format the time string
  return `${hours === 0 ? 12 : hours}:${minutes < 10 ? '0' : ''}${minutes}${period}`;
}

export function getTimeAsDdMmYyyy(): string {
  const today = new Date();

  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = today.getFullYear();

  return `${day}/${month}/${year}`;
}

export const getBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export function shiftSetValuesUp(set: Set<number>): Set<number> {
  const incrementedSet = new Set<number>();

  for (const value of set) {
    incrementedSet.add(value + 1);
  }

  return incrementedSet;
}

export function toggleExpandSet(expandSet: Set<number>, index: number) {
  if (expandSet.has(index)) {
    expandSet.delete(index);
  } else {
    expandSet.add(index);
  }
}

export function getDynamicBoxShadow(color: string | undefined): string {
  return `0 8px 12px -4px ${(color || '#6d757e') + '40'}`;
}
