import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import CryptoJS from 'crypto-js';
import { debounceTime, firstValueFrom, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import UAParser from 'ua-parser-js';
import { IGeolocationInfo } from '../interfaces';
import { DeviceModelRequestDto } from '../models';
import { UserDeviceService } from './user-device.service';

@Injectable({ providedIn: 'root' })
export class FingerprintService {
  readonly #httpClient = inject(HttpClient);
  readonly #userDeviceService = inject(UserDeviceService);

  #parser!: UAParser;

  initializeFingerprint() {
    const { userAgent } = window.navigator;
    this.#parser = new UAParser(userAgent);
    this.generateDeviceFingerprint().then(device => {
      if (device) {
        this.#userDeviceService.loginDevice(device);
      }
    });
  }

  private async generateDeviceFingerprint(): Promise<DeviceModelRequestDto | undefined> {
    const { language, geolocation, hardwareConcurrency, platform } = window.navigator;
    const deviceDetails = this.#parser.getResult();
    const { country, country_code, city, latitude, longitude, ip, security } = await this.getGeolocationInfo();
    const hashObject = {
      browser_name: deviceDetails.browser.name,
      browser_version: deviceDetails.browser.version,
      browser_major: deviceDetails.browser.major,
      device_type: deviceDetails.device.type,
      device_model: deviceDetails.device.model,
      os_name: deviceDetails.os.name,
      os_version: deviceDetails.os.version,
      country,
      country_code,
      city,
      latitude,
      longitude,
      ip,
      language,
      geolocation,
      hardwareConcurrency,
      platform,
      color_depth: window.screen.colorDepth,
    };

    if (security && (security.proxy || security.hosting || security.vpn)) {
      return;
    }

    const encryptedHashedValue = CryptoJS.SHA256(JSON.stringify(hashObject)).toString();

    const device: DeviceModelRequestDto = {
      name: hashObject.device_model || `${hashObject.os_name || ''} ${hashObject.os_version || ''}`,
      visitorId: encryptedHashedValue,
      userAgent: `${hashObject.os_name} ${hashObject.os_version} - ${hashObject.browser_name} ${hashObject.browser_version}`,
    };

    return device;
  }

  getGeolocationInfo() {
    return firstValueFrom(
      this.#httpClient
        .get<IGeolocationInfo>(environment.GEOLOCATION_API_URL, {
          headers: {
            accept: 'application/json',
          },
        })
        .pipe(shareReplay(1), debounceTime(500)),
    );
  }
}
