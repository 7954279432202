import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { ALLERGENS_PATH, PRODUCTS_PATH, VENUES_PATH } from '../constants';
import { AllergenModelRequestDto, AllergenModelResponseDto } from '../models';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class AllergensService {
  readonly #apiService = inject(ApiService<AllergenModelRequestDto, AllergenModelResponseDto>);

  // Make sources private so it's not accessible from the outside
  readonly #allergensSource: WritableSignal<AllergenModelResponseDto[] | undefined> = signal(undefined);

  // Exposed signals (read-only)
  readonly allergens = this.#allergensSource.asReadonly();

  getAllAllergensOfAVenue(venueId: string): Promise<AllergenModelResponseDto[]> {
    return this.#apiService.getAll(`${VENUES_PATH}/${venueId}/${ALLERGENS_PATH}`);
  }

  getAllAllergensOfAProduct(productId: string): Promise<AllergenModelResponseDto[]> {
    return this.#apiService.getAll(`${PRODUCTS_PATH}/${productId}/${ALLERGENS_PATH}`);
  }

  createAllergens(allergens: AllergenModelRequestDto[]): Promise<AllergenModelResponseDto[]> {
    return this.#apiService.postMany(`${ALLERGENS_PATH}`, allergens);
  }

  setAllergens(allergens: AllergenModelResponseDto[]) {
    this.#allergensSource.set(allergens);
  }
}
